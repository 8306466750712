<template>
  <div class="swipernb">
    <svg
      class="swipernb__icon"
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 216.2 381"
      style="enable-background:new 0 0 216.2 381;"
      xml:space="preserve"
    >
      <path class="swipernb__fill" d="M216.2,190.5L0,381V0L216.2,190.5z" />
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.swipernb {
  cursor: pointer;
}
.swipernb__icon {
  width: 100%;
  height: 100%;
}
.swipernb__fill {
  fill: #c79b6b;
}
</style>
