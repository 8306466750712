<template>
  <div class="gallerietwo">
    <div class="gallerietwo__wrapper">
      <div class="gallerietwo__content">
        <div class="gallerietwo__leftblock">
          <div
            class="gallerieMotion gallerietwo__item gallerietwo__item--u_31 gallerietwo__item--big"
            data-motion="'hallo'"
          >
            <GalleryPic
              :imgpath="'assets/img/01_ueberuns/21_06_07_THI_1171.jpg'"
              :imgposition="'center 50%'"
            />
          </div>

          <div
            class="gallerieMotion gallerietwo__item gallerietwo__item--u_33 gallerietwo__item--big"
            data-motion="'hallo'"
          >
            <GalleryPic
              :imgpath="'assets/img/01_ueberuns/21_06_07_THI_0365.jpg'"
              :imgposition="'center 50%'"
            />
          </div>
        </div>
        <div class="gallerietwo__rightblock">
          <div
            class="gallerieMotion gallerietwo__item gallerietwo__item--u_34 "
            data-motion="'hallo'"
          >
            <UeberTextInfo :h1="true">
              <template v-slot:h1> Location</template>
              <template v-slot:p>
                Wir legen Wert auf ein besonderes Ambiente, das zum Wohlfühlen
                und Verweilen einlädt. In unserem großen Gastraum sorgen
                Oberflächen wie Leder, Holz und Messing für ein harmonisches
                Gesamtbild und ein gutes Gefühl wie in den berühmten Brasserien
                aus Frankreich.

                <br />
                <br />
                Unser Ziel beim Design der Inneneinrichtung war immer, dass wir
                selbst gerne Zeit in der Brasserie verbringen. Daher freuen wir
                uns umso mehr, wenn Sie bei uns in der Altstadt vorbeikommen und
                in unseren Räumen gut versorgt verweilen.
              </template>
            </UeberTextInfo>
          </div>

          <div
            class="gallerieMotion gallerietwo__item gallerietwo__item--u_35 gallerietwo__item  gallerietwo__item--small"
            data-motion="'hallo'"
          >
            <GalleryPic
              :imgpath="'assets/img/00_startseite/header/cover.jpg'"
              :imgposition="'center 70%'"
            />
          </div>
          <div
            class="gallerieMotion gallerietwo__item gallerietwo__item--u_36 gallerietwo__item--big"
            data-motion="'hallo'"
          >
            <SwiperGallery :dataObject="gallerie_4" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperGallery from "@/components/common/swiper/SwiperGallery.vue";
import GalleryPic from "@/components/common/gallery/GalleryPic.vue";
import getPathFunction from "@/utilities/composition/getPathFunction";
import UeberTextInfo from "./UeberTextInfo.vue";
export default {
  components: { GalleryPic, SwiperGallery, UeberTextInfo },
  setup() {
    const { setBackGroundPath } = getPathFunction();

    const gallerie_4 = [
      "assets/img/01_ueberuns/gallerie_4/1.jpg",
      "assets/img/01_ueberuns/gallerie_4/2.jpg",
      "assets/img/01_ueberuns/gallerie_4/3.jpg",
      "assets/img/01_ueberuns/gallerie_4/4.jpg",
      "assets/img/01_ueberuns/gallerie_4/5.jpg",
    ];
    return { setBackGroundPath, gallerie_4 };
  },
};
</script>

<style lang="scss">
.gallerietwo__item {
  &--u_31 {
    width: 100%;
    flex: 1 1 auto;
  }
  &--u_32 {
    width: 100%;
    flex: 0 1 auto;
  }
  &--u_33 {
    width: 100%;
    flex: 0 1 auto;
  }
  &--u_34 {
    width: 100%;
    flex: 0 0 auto;
    order: 1;
    @media screen and (min-width: $medium) {
      order: 1;
    }
  }
  &--u_35 {
    width: 100%;
    flex: 0 0 auto;
    order: 2;
    @media screen and (min-width: $medium) {
      order: 2;
    }
  }
  &--u_36 {
    width: 100%;
    flex: 1 1 auto;
    order: 3;
    @media screen and (min-width: $medium) {
      order: 3;
    }
  }
}
</style>
