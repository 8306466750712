<template>
  <div class="ueberti">
    <div class="ueberti__wrapper">
      <h1 v-if="h1" class="ueberti__h1 h1 h1--green">
        <slot name="h1"></slot>
      </h1>
      <p class="p p-green ueberti__p">
        <slot name="p"></slot>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: { h1: Boolean },
  setup() {},
};
</script>

<style lang="scss">
$piblock: 60px;

.ueberti {
  margin: 0 auto;
  //   margin-top: -200px;
  background-color: none;
  position: relative;
}
.ueberti__wrapper {
  padding-top: 54px;
  padding-left: $main-padding-section-s;
  padding-right: $main-padding-section-s;

  padding-bottom: 54px;
  text-align: center;

  @media screen and (min-width: $medium) {
  }
  @media screen and (min-width: $large) {
  }
  @media screen and (min-width: $xlarge) {
  }
  @media screen and (min-width: $xxlarge) {
  }
}

.ueberti__boxbutton {
  width: 100%;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 4px;
}
.ueberti__h1 {
  margin-bottom: 45px;
}

.ueberti__btn {
  display: block;
  margin-top: 15px;
  flex: 0 0 auto;
}
.ueberti__p {
  margin: 0 auto;
  padding-bottom: 0;
}
</style>
