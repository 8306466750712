<template>
  <div class="page page__ueber">
    <HeaderOne
      :imgpath="'assets/img/01_ueberuns/header/cover.jpg'"
      :imgposition="'center 60%'"
    />
    <IntroBlockUeber class="section" />
    <UeberSectionOne class="section" />
    <Divider class="section" />
    <UeberSectionTwo class="section section" />
    <Divider class="section" />
    <UeberSectionThree class="section section" />
    <TheFooter class="section" />
  </div>
</template>

<script>
import Divider from "@/components/common/divider/Divider/";
import HeaderOne from "@/components/common/header/HeaderOne";
import IntroBlockUeber from "@/components/common/introblock/IntroBlockUeber.vue";
import UeberSectionOne from "../components/common/ueber/UeberSectionOne.vue";
import UeberSectionTwo from "../components/common/ueber/UeberSectionTwo.vue";
import UeberSectionThree from "../components/common/ueber/UeberSectionThree.vue";
import TheFooter from "../components/common/footer/TheFooter.vue";

import { onBeforeUnmount, onMounted, onUpdated, ref } from "@vue/runtime-core";
//gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  components: {
    HeaderOne,
    IntroBlockUeber,
    UeberSectionOne,
    UeberSectionTwo,
    UeberSectionThree,
    TheFooter,
    Divider,
  },

  setup() {
    const dataMotionArray = ref([]);
    const scrollTriggerArray = ref([]);
    onMounted(() => {
      dataMotionArray.value = document.querySelectorAll("[data-motion]");
      dataMotionArray.value.forEach((element) => {
        let scrollT = null;

        let el = element;

        let tl = gsap.timeline({
          // yes, we can add it to an entire timeline!
        });

        tl.set(el, { force3D: true });

        tl.set(el, { force3D: true });
        tl.from(el, {
          y: "30%",
          rotation: 0.02,
          duration: 1,
          ease: "power1.out",
          opacity: 0,
        });

        // ScrollTrigger.matchMedia({
        //   // large
        //   "(min-width: 960px)": function() {
        //     tl.set(el, { force3D: true });
        //     tl.from(el, {
        //       y: "30%",
        //       rotation: 0.02,
        //       duration: 1,
        //       ease: "none",
        //       opacity: 0,
        //     });
        //   },
        //   // small
        //   "(max-width: 959)": function() {
        //     tl.set(el, { force3D: true });
        //     tl.from(el, {
        //       // y: "30%",
        //       rotation: 0.02,
        //       duration: 1,
        //       ease: "none",
        //       opacity: 0,
        //     });
        //   },

        //   // all
        //   all: function() {
        //     // ScrollTriggers created here aren't associated with a particular media query,
        //     // so they persist.
        //   },
        // });

        scrollT = ScrollTrigger.create({
          // scroller: "#main__content",
          animation: tl,
          trigger: el,
          scrub: 0.5,
          start: "top bottom",
          end: "top 80%",
          // markers: { startColor: "green", endColor: "red", fontSize: "12px" },

          // onToggle: (self) =>
          // onUpdate: (self) => {
          //   // console.log(
          //   //   "progress:",
          //   //   self.progress.toFixed(3),
          //   //   "direction:",
          //   //   self.direction,
          //   //   "velocity",
          //   //   self.getVelocity()
          //   // );
          // },
        });
        ScrollTrigger.refresh();
        scrollTriggerArray.value.push(scrollT);
      });
      ScrollTrigger.update();
      ScrollTrigger.refresh();
      ScrollTrigger.update();
    });

    onBeforeUnmount(() => {
      ScrollTrigger.getAll().forEach((st) => st.kill());
    });

    onUpdated(() => {
      ScrollTrigger.update();
      ScrollTrigger.refresh();
      ScrollTrigger.update();
    });

    return { dataMotionArray };
  },
};
</script>
<style
  lang="
    scss"
></style>
