<template>
  <div class="swiper__container">
    <div
      id="swiper__swiper-container"
      class="swiper__swiper-container swiper-container"
      ref="swiper"
    >
      <div class="swiper__swiper--wrapper swiper-wrapper">
        <div
          class="swiper__swiper--slide swiper-slide"
          v-for="el in dataObject"
          :key="el"
        >
          <!-- <GalleryPic :imgpath="el" />
           -->

          <div
            class="swiper__swiper--img swiper-lazy"
            :data-background="getPath(el)"
          >
            <div
              class="swiper-lazy-preloader swiper-lazy-preloader-white"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <button class="swiper__nextbtn no-touch-out" ref="swiperNextbtn">
      <SwiperNextBtn />
    </button>
  </div>
</template>

<script>
// import Swiper JS
import SwiperNextBtn from "./SwiperNextBtn.vue";
import Swiper from "swiper/bundle";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";

import { onMounted, onBeforeUnmount, ref } from "vue";
// import GalleryPic from "../gallery/GalleryPic.vue";

import getPathFunction from "@/utilities/composition/getPathFunction";

export default {
  props: { dataObject: Array },
  components: { SwiperNextBtn },

  setup() {
    let eventsSwiper = null;

    const { getPath } = getPathFunction();

    const swiper = ref(null);
    const swiperNextbtn = ref(null);

    onMounted(() => {
      eventsSwiper = new Swiper(swiper.value, {
        // autoplay: {
        //   // delay: 2500,
        //   disableOnInteraction: false,
        // },
        autoplayDisableOnInteraction: false,
        watchSlidesVisibility: true,
        preloadImages: false,
        // Enable lazy loading
        lazy: {
          loadPrevNext: true,
        },
        slidesPerView: 1,
        loop: false,
        grabCursor: true,
        navigation: {
          nextEl: swiperNextbtn.value,
        },
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
        },
      });
    });
    onBeforeUnmount(() => {});

    return { eventsSwiper, getPath, swiper, swiperNextbtn };
  },
};
</script>

<style lang="scss">
.et {
  margin: 0 auto;
  max-width: $size-app-max-width;

  margin-bottom: $margin-bottom-section;
}

.swiper__container {
  position: relative;
  width: 100%;
  height: 100%;

  padding: 0 0;
}
.swiper__wrapper {
  width: 100%;
  height: 100%;
}

//NESTING
.islive {
  &.swiper__item {
    background: #ff5555;
  }

  .swiper__hl {
    color: white;
  }
  .swiper__icon {
    background: rgba($color: white, $alpha: 0.5);
    color: white;
  }
}

.swiper__nextbtn {
  position: absolute;
  right: -16px;
  top: 50%;
  transform: translate(0, -50%);

  width: 32px;
  height: 56px;
  z-index: 20;
  background-color: unset;
  background: none;
  outline: none;
  border: none;
  padding: 0;
  opacity: 1;

  transition: 0.4s;

  &.swiper-button-disabled {
    opacity: 0.3;
  }
}

//NESTED THINGS
.swiper__swiper-container {
  height: 100%;

  // background-color: $main-color-1;
  // overflow: visible;
}

.swiper__swiper--img {
  width: 100%;
  height: 100%;

  background-position: center;
  background-size: cover;
}
</style>
