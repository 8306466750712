<template>
  <div class="introb1">
    <div class="introb1__wrapper">
      <div class="introb1__content">
        <h1 class="introb1__h1 h1 h1--white">Modern French dining</h1>
        <p class="p p--white introb1__p">
          Herzlich willkommen in der Brasserie Thi, Ihrem erstklassigen
          Ziel für authentische französisch-mediterrane Küche im Herzen
          von München. Tauchen Sie ein in eine kulinarische Reise,
          die die lebendigen Aromen und reichen Traditionen der Mittelmeerküste
          feiert. Unsere Speisekarte bietet eine köstliche
          Auswahl an regionalem Fleisch, frischen Fischen und Meeresfrüchten,
          duftenden Kräutern und saisonalen Zutaten, die mit
          Leidenschaft und Kunstfertigkeit von unserem talentierten
          Küchenteam zubereitet werden.
          <br><br>
          Ob Sie ein entspanntes Mittagessen, ein romantisches Dinner
          oder eine besondere Feier genießen möchten, unsere warme
          und einladende Atmosphäre, ergänzt durch sorgfältig ausgewählte
          Weine aus der Region, bilden den perfekten Rahmen
          für Ihr kulinarisches Erlebnis. Besuchen Sie uns und genießen
          Sie das Beste der französischen Küche mit ausgewählten
          mediterranen Akzenten– wo jede Mahlzeit eine Feier des Geschmacks,
          der Freundschaft und der Freude am guten Essen
          ist. Wir freuen uns darauf, Sie bei uns willkommen zu heißen!
        </p>

        <div class="introb1__logo">
          <TheLogo />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheLogo from "@/components/ui/logo/TheLogo.vue";
export default {
  components: { TheLogo },
};
</script>

<style lang="scss"></style>
